import React, { useState } from "react";
import {
    WppInput,
    WppSelect,
    WppTypography,
    WppButton,
    WppListItem,
    WppCheckbox,
    WppTooltip,
    WppIconHelp
} from "@wppopen/components-library-react";
import { AudienceOriginRequest, EndPoints } from "../../api/audience-origin";
import { useCookies } from "react-cookie";
import axios, { AxiosRequestConfig } from "axios";
import { AppConfig } from "../../AppConfig";
import { event } from "jquery";

type Props ={
    testSuite: string;
    projectId: string;
    onDagSuccess: () => void;
};

export const TriggerDag = (props: Props) => {
    const [surveys, setSurveys] = useState("");
    const [useAscription, setUseAscription] = useState(true);
    const[globaltable,setGlobaltable] =useState("yes");
    const [db_table_prefix,setdb_table_prefix] = useState("");
    const[market,setmarket]=useState("");
    const [year ,setyear] = useState("");
    
    const [status, setStatus] = useState("-");
    const [dagId, setDagId] = useState("");
    const [ischecked,setischecked]= useState(true);
    const [dagRunId, setDagRunId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);

    const [cookies] = useCookies([ 'csrf_access_token']);

    console.log(cookies);
    const csrfToken = cookies.csrf_access_token;
    console.log(csrfToken);
    const accessToken =  localStorage.getItem("access_token");
    const triggerExternalApi = async (datconfig1, csrfToken, accessToken) => {
        try {
            const config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken, 
                  },
                  withCredentials: true,  
                };

            const apiUrl = `${AppConfig.alluerDockerApiUrl}/projects`;
            console.log(apiUrl);
            await axios.post(apiUrl, datconfig1, config);
        } catch (error) {
            console.error("Error triggering external API:", error);
        }

    };

    const handleInputChange = async () => {
        if (!surveys || !props.testSuite || !props.projectId || useAscription === null) {
            setErrorMessage("Please fill out all required fields.");
            setHasError(true);
            return;
        }

        const datconfig1 = {
            id: props.projectId
            
        };

        const datconfig = {


            "export_validation": {

            is_global_table_created: globaltable,
            db_table_prefix: db_table_prefix,
            markets: market,
            year: Number(year),
            },
            use_ascription: useAscription,
            surveys,
            test_suite: props.testSuite,
            project_id: props.projectId,
            
        };

        const dagName = "ao_qa";

        try {
            setHasError(false);
            setIsLoading(true);

            const [triggerStatusResponse, apiResponse] = await Promise.all([
                AudienceOriginRequest.post(
                    `${EndPoints.RawDataTriggerDAG}/${dagName}`,
                    datconfig,
                    { headers: { "Content-Type": "application/json" } }
                ),
                triggerExternalApi(datconfig1, csrfToken, accessToken)
            ]);

            setStatus(triggerStatusResponse.data.state);
            setDagId(triggerStatusResponse.data.dagId);
            setDagRunId(triggerStatusResponse.data.dagRunId);
            props.onDagSuccess();

        } catch (error) {
            setErrorMessage(error.response?.data?.message ?? error.message);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAscriptionChange = (e) => {
        setUseAscription(e.target.value === "true");
    };
    const handlegloabaltablechange = (e) => {
        setGlobaltable(e.target.checked? "Yes":"no"); 
    };

    const handletableChange = (e) => {
        setdb_table_prefix(e.target.value);
    };
    const handlemarketChange = (e) => {
        setmarket(e.target.value);
    };

    const handleyearChange = (e) => {
        setyear(e.target.value);
    };

    const handleChange = (e) => {
        setSurveys(e.target.value);
    };


    const handleButtonClick = () => {
        handleInputChange();
    };

    const metadataAirflowLogs = AppConfig.airflowBaseUrl + "/ao_qa/graph"

    return (
        <div>
            {status === "-" ? (
                                  <div style={{ display: "contents", alignItems: "center" }}>

                    <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center",marginRight: "5rem",minWidth:'150px' }}>

                        <label >Survey name</label>
                        <WppTooltip text="Enter the name of the survey.(e.g:US-3X-W1,UK-3X-W1)">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        </div>
                        <WppInput value={surveys} onWppChange={handleChange} style={{ width:"300px"}} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "center",marginRight: "5rem",minWidth:'150px' }}>
                        <label >Project ID        </label>
                        <WppTooltip text="Project identifier for tracking.">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        </div>
                        <WppInput value={props.projectId} style={{ width:'300px' }} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                    <div style={{ display: "flex", alignItems: "center",marginRight: "5rem",minWidth:'150px' }}>

                        <label >Test Suite</label>
                        <WppTooltip text="Test suite for the current project.">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        </div>
                        <WppInput value={props.testSuite} style={{ width:"300px"}} />
                    </div>


                    

                    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                    <div style={{ display: "flex", alignItems: "center",marginRight: "5rem",minWidth:'150px' }}>

                        <label >Use Ascription</label>
                        <WppTooltip text="Specify whether to use ascription for this survey.">
                            <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                        </WppTooltip>
                        </div>
                        <WppSelect
                            onWppChange={handleAscriptionChange}
                            placeholder="use_ascription"
                            value={useAscription === null ? "true" : useAscription ? "true" : "false"}
                            style={{ width:"300px" }}
                        >
                            <WppListItem value="true">
                                <p slot="label">True</p>
                            </WppListItem>
                            <WppListItem value="false">
                                <p slot="label">False</p>
                            </WppListItem>
                        </WppSelect>
            
                       
                        </div>

                       
   


{ props.testSuite ==="db_export_validation" && (


    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "center",marginRight: "5rem",minWidth:'150px' }}>

                            <label >db table prefix</label>
                            <WppTooltip text="Enter the name of db_table_prefix.">
                                <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                            </WppTooltip>
                            </div>
                            <WppInput value={db_table_prefix} onWppChange={handletableChange} style={{ width:"300px" }} />
                        </div>
)}

{ props.testSuite ==="db_export_validation" && (
                        <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "center",marginRight: "2rem",minWidth:'150px' }}>

                                <label >global table created</label>
                                <WppTooltip text="Specify whether to use global table for this survey.">
                                    <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                                </WppTooltip>
                                </div>
                                <div style={{marginLeft:"35px"}}>

                               <WppCheckbox
                                checked={globaltable==='yes'}
                                onWppChange={handlegloabaltablechange}/>
                                </div>
                                                                  
                            </div>
)}
{ props.testSuite ==="db_export_validation" && (
                            <div style={{ display: "flex", alignItems: "center", margin: "1rem" }}>
                           <div style={{ display: "flex", alignItems: "center",marginRight: "4rem",minWidth:'150px' }}>

                                <label >markets</label>
                                <WppTooltip text="Enter the name of markets.(e.g: AU,US,UK)">
                                    <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                                </WppTooltip>
                                </div>
                                <WppInput value={market} onWppChange={handlemarketChange} style={{ width:"300px" }} />
                            </div>
)}
{ props.testSuite ==="db_export_validation" && (
                            <div style={{ display: "flex", alignItems: "center", margin: "1rem" }}>
                             <div style={{ display: "flex", alignItems: "center",marginRight: "4rem",minWidth:'150px' }}>
           
                                <label>year</label>
                                <WppTooltip text="Enter the name of year.e.g(YYYY)">
                                    <WppIconHelp style={{ marginLeft: "0.25rem", cursor: "pointer" }} />
                                </WppTooltip>
                                </div>
                                <WppInput type="number" value={year} onWppChange={handleyearChange} style={{ width:"300px" }} />
                            </div>
)}
                            


                            <div style={{ display: "flex", justifyContent: "center",marginTop: "2rem" }}>

                    <WppButton onClick={handleButtonClick} >
                        {isLoading ? "Triggering DAG..." : "Trigger DAG"}
                    </WppButton>
                    </div>

                    {hasError && (
                        <p style={{ color: "red", marginTop: "0.5rem" }}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            ) : (
                <WppTypography type="l-body">
                    <ul>
                        <li>Status of DAG: {status}</li>
                        <li>DAG Id: {dagId}</li>
                        <li>DAG Run Id: {dagRunId}</li>
                        <li>
                            Link for airflow UI where user can check logs
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={metadataAirflowLogs}
                                    >
                                        Click Here
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </WppTypography>
            )}
        </div>
    );
};