import { Hidden } from "@mui/material";
import { AppConfig } from "../../../AppConfig";
import { Field } from "@formily/core";

export const ExportDagsConstants = {
    marketExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338319122470/AO+export+pipeline",
    marketsBatchExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338319417785/AO+export+batch+pipeline",
    globalExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338319122470/AO+export+pipeline",
    globalDecodedExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338319122470/AO+export+pipeline",
    globalSpssWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338318368777/AO+global+spss+export+pipeline",
    triggerExportMarketDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_snowflake_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_snowflake_export/graph`,
        inputFields: [
            {
                fieldId: "snowflake_schema",
                fieldLabel: "Snowflake Schema",
                fieldType: "string",
                tooltip: "the snowflake schema to export, e.g. AO_EXPORT_SCHEMA",
                defaultValue: "AO_EXPORT_SCHEMA",
                
               
            },
            {
                fieldId: "core_table_prefix",
                fieldLabel: "Core Table Prefix",
                fieldType: "string",
                tooltip: "the core table prefix to export, e.g. TEST_AO_CORE_",
                defaultValue: "AO_CORE_"

            },
            {
                fieldId: "meta_table_prefix",
                fieldLabel: "Meta Table Prefix",
                fieldType: "string",
                tooltip: "the meta table prefix to export, e.g. TEST_AO_METADATA_",
                defaultValue: "AO_METADATA_"

            },
            {
                fieldId: "is_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "whether to export ascription table data"
            },
            {
                fieldId: "survey_version",
                fieldLabel: "Release/Year",
                fieldType: "string",
                tooltip: "Cross year waves defined as a release in ao_year_wave_mapping table(e.g 2x) or Year(e.g 23) if survey_wave and survey_market are used",
                required: true
            },
            {
                fieldId: "survey_market",
                fieldLabel: "Market",
                fieldType: "array",
                tooltip: "the markets to export, e.g. SG, AU",
                required: true
            },
            {
                fieldId: "survey_wave",
                fieldLabel: "Wave Label",
                fieldType: "string",
                tooltip: "a wave label to export, e.g. W1, please specify this if survey_version is a year value and not a release",
            },
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
            {
                fieldId: "only_metadata",
                fieldLabel: "Export only Metadata: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export only metadata"
            },
            {
                fieldId: "internal_export",
                fieldLabel: "Export to internal schema: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export to internal schema if Yes,use schema: AO_INTERNAL_EXPORT_SCHEMA"
            },
           
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE",

            },

        
        ]
    },



    

    triggerExportGlobalDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_snowflake_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_snowflake_export/graph`,
        inputFields: [
            {
                fieldId: "snowflake_schema",
                fieldLabel: "Snowflake Schema",
                fieldType: "string",
                tooltip: "the snowflake schema to export, e.g. AO_EXPORT_SCHEMA",
                defaultValue: "AO_EXPORT_SCHEMA"
            },
            {
                fieldId: "core_table_prefix",
                fieldLabel: "Core Table Prefix",
                fieldType: "string",
                tooltip: "the core table prefix to export, e.g. TEST_AO_CORE_",
                defaultValue: "AO_CORE_"

            },
            {
                fieldId: "meta_table_prefix",
                fieldLabel: "Meta Table Prefix",
                fieldType: "string",
                tooltip: "the meta table prefix to export, e.g. TEST_AO_METADATA_",
                defaultValue: "AO_METADATA_"

            },
            {
                fieldId: "is_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "whether to export ascription table data"
            },
            {
                fieldId: "survey_version",
                fieldLabel: "Release/Year",
                fieldType: "string",
                tooltip: "Cross year waves defined as a release in ao_year_wave_mapping table(e.g 2x) or Year(e.g 23) if survey_wave and survey_market are used",
                required: true
            },
            {
                fieldId: "survey_market",
                fieldLabel: "Market",
                fieldType: "array",
                tooltip: "the markets to export, e.g. SG, AU",
                required: true
            },
            {
                fieldId: "survey_wave",
                fieldLabel: "Wave Label",
                fieldType: "string",
                tooltip: "a wave label to export, e.g. W1, please specify this if survey_version is a year value and not a release",
            },
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
            {
                fieldId: "only_metadata",
                fieldLabel: "Export only Metadata: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export only metadata"
            },
            {
                fieldId: "internal_export",
                fieldLabel: "Export to internal schema: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export to internal schema if Yes,use schema: AO_INTERNAL_EXPORT_SCHEMA"
            },
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            },
         
            {
                fieldId: "is_global",
                fieldLabel: "Global: ",
                fieldType: "boolean",
                defaultValue: true,
                disabled:true,
                tooltip: "Please keep this checked for global export"
            }
        ]
    },

    triggerBatchExportDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_batch_trigger_snowflake_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_batch_trigger_snowflake_export/graph`,
        inputFields: [
            {
                fieldId: "snowflake_schema",
                fieldLabel: "Snowflake Schema",
                fieldType: "string",
                tooltip: "the snowflake schema to export, e.g. AO_EXPORT_SCHEMA",
                defaultValue: "AO_EXPORT_SCHEMA"
            },
            {
                fieldId: "meta_table_prefix",
                fieldLabel: "Meta Table Prefix",
                fieldType: "string",
                tooltip: "the meta table prefix to export, e.g. TEST_AO_METADATA_",
                defaultValue: "AO_METADATA_"

            },
            {
                fieldId: "core_table_prefix",
                fieldLabel: "Core Table Prefix",
                fieldType: "string",
                tooltip: "the core decode table prefix to export, e.g. TEST_AO_CORE_",
                defaultValue: "AO_CORE_"

            },
            {
                fieldId: "is_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to use ascription table for export"
            },
            {
                fieldId: "log level",
                fieldLabel: "log level:",
                fieldType: "dropdown",
                options: [
                    { label: "INFO", "value": "info" },
                    { label: "DEBUG", "value": "debug" }
                ],
                tooltip: "select one log level, e.g.info,debug",
                defaultValue: "info"

            },
            {
                fieldId: "market_release",
                fieldLabel: "Surveys:",
                fieldType: "array",
                tooltip: "Surveys to export (e.g.SG-2X-W1, SG-1X-OFFLINEW1, if ascription is checked, wave label part will be ignored. e.g. it takes SG-2X from SG-2X-W1)"
            },
          
            
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
            {
                fieldId: "only_metadata",
                fieldLabel: "Export only Metadata: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export only metadata"
            },
            {
                fieldId: "internal_export",
                fieldLabel: "Export to internal schema: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export to internal schema if Yes,use schema: AO_INTERNAL_EXPORT_SCHEMA"
            },
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            }
        ]
    },
    triggerExportGlobalDecodedDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_snowflake_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_snowflake_export/graph`,
        inputFields: [
            {
                fieldId: "snowflake_schema",
                fieldLabel: "Snowflake Schema",
                fieldType: "string",
                tooltip: "the snowflake schema to export, e.g. AO_EXPORT_SCHEMA",
                defaultValue: "AO_PB_SCHEMA"
            },
            {
                fieldId: "core_decoded_table_prefix",
                fieldLabel: "Core Decode Table Prefix",
                fieldType: "string",
                tooltip: "the core decode table prefix to export, e.g. TEST_AO_CORE_DECODED_",
                defaultValue: "AO_CORE_DECODED_"

            },
            {
                fieldId: "is_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "whether to export ascription table data"
            },
            {
                fieldId: "survey_version",
                fieldLabel: "Release/Year",
                fieldType: "string",
                tooltip: "Cross year waves defined as a release in ao_year_wave_mapping table(e.g 2x) or Year(e.g 23) if survey_wave and survey_market are used",
                required: true
            },
            {
                fieldId: "survey_market",
                fieldLabel: "Markets",
                fieldType: "array",
                tooltip: "the markets to export, e.g. SG, AU",
                required: true
            },
            {
                fieldId: "survey_wave",
                fieldLabel: "Wave Label",
                fieldType: "string",
                tooltip: "a wave label to export, e.g. W1, please specify this if survey_version is a year value and not a release",
            },
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
           
           
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            },
            {
                fieldId: "is_decode",
                fieldLabel: "Decode: ",
                fieldType: "boolean",
                defaultValue: true,
                disabled:true,
                tooltip: "Please keep this checked for decode export",
            },
            
        ]
    },
    triggerSpssDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_global_spss_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_global_spss_export/graph`,
        inputFields: [
            {
                fieldId: "schema_name",
                fieldLabel: "Export Schema Name: ",
                fieldType: "string",
                tooltip: "Schema name of the table that needs to be exported, e.g. AO_EXPORT_SCHEMA_WITH_IDS",
                defaultValue: "AO_EXPORT_SCHEMA_WITH_IDS"
            },
            {
                fieldId: "table_name",
                fieldLabel: "Export Table Name: ",
                fieldType: "string",
                tooltip: "table name that needs to be exported, e.g. AO_CORE_1X_GLOBAL"
            },
            {
                fieldId: "gcs_base_path",
                fieldLabel: "Export GCS Location: ",
                fieldType: "string",
                defaultValue: "pipelines/ao_global_spss_export",
                tooltip: "the GCS location where the spss files will be exported"
            }
        ]
    },
}
