import React, { useContext } from "react";
import {
  WppSelect,
  WppListItem,
} from "@wppopen/components-library-react";
import { color } from "echarts";

const SPEC_FILE_TEMPLATES = [
    {
        name: "db_checks",
        value: "db_checks",
    },
    {
        name: "db_file_checks",
        value: "db_file_checks",
    },
    {
        name: "global_view_checks",
        value: "global_view_checks",
    },
    {
        name: "db_export_validation",
        value: "db_export_validation",
    }
];

type Props = {
  value: string;
  errorMessage: string;
  onValueChange: (value: string) => void;
};

export const SurveySelectStep = (props: Props, errorMessage) => {
  const handleChange = (event: CustomEvent) => {
    props.onValueChange(event.detail.value);
  };
  console.log(errorMessage);

  return (
    <div>
      <WppSelect
        style={{
          width: "100%",
          height: "60%",
          color: "red",
        }}
        onWppChange={handleChange}
        placeholder="please select test suite"
        value={props.value}
        labelConfig={{ text: "please select test suite" }}
        required
      >
        {SPEC_FILE_TEMPLATES.map((template) => (
          <WppListItem value={template.value} key={template.value}>
            <p slot="label">{template.value}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {!props.value && (
        <p style={{ color: "red" }}> Please select a option to proceed</p>
      )}
    </div>
  );
};
